import React from "react";
import { Outlet } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";

import Main from "../components/Main";

const Onboarding = ({ children }) => (
  <React.Fragment>
    <Main className="d-flex w-100 justify-content-center">
      <Container className="d-flex flex-column">
        <Row className="h-100">
          <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle" style={{paddingLeft: "25px", paddingRight: "25px"}}>
              {children}
              <Outlet />
            </div>
          </Col>
        </Row>

          <div className="text-center mt-4">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="mailto:support@h3althcare.com">support@h3althcare.com</a>
              </span>
            </li>
            |&nbsp;&nbsp;
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="https://www.h3althcare.com/privacy-statement">Privacy</a>
              </span>
            </li>
            |&nbsp;&nbsp;
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="https://www.h3althcare.com/terms-and-conditions">Terms</a>
              </span>
            </li>
          </ul>
            <div style={{marginTop: "-15px", marginBottom: "15px"}}>
              Copyright &copy; {new Date().getFullYear()} -{" "}
              <span className="text-muted">
                <a href="https://www.h3althcare.com/" target="_blank">H3 Healthcare Holdings LLC</a>
              </span>
            </div>
          </div>
      </Container>
    </Main>
  </React.Fragment>
);

export default Onboarding;
