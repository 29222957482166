import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Modal, Form, Card, Spinner, ButtonGroup, ToggleButton} from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";
import authenticatedFetch from "./fetch"

import EditableExpires from "./EditableExpires";
import EditableExpiresControls from "./EditableExpiresControls";

import {  CheckCircle, XCircle} from "react-feather";


const ViewEditSelect = ({ selectedMode, handleModeChanged}) => {
  return (
      <ButtonGroup className="me-auto" size="sm">
          <ToggleButton
              key="VIEW"
              id="VIEW"
              type="radio"
              variant="outline-primary"
              style={{color: selectedMode === "VIEW" ? "white" : ""}}
              value="VIEW"
              checked={selectedMode === "VIEW"}
              onChange={(e) => {handleModeChanged(e.target.value)}}
          >View</ToggleButton>
          <ToggleButton
              key="EDIT"
              id="EDIT"
              type="radio"
              variant="outline-primary"
              style={{color: selectedMode === "EDIT" ? "white" : ""}}
              value="EDIT"
              checked={selectedMode === "EDIT"}
              onChange={(e) => {handleModeChanged(e.target.value)}}
          >Edit</ToggleButton>
      </ButtonGroup>
  )
}

const BusinessLineSelect = ({ practiceId, carrierId, selectedBusinessLineId, handleBusinessLineChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (practiceId > 0 && carrierId > 0)
    {
      authenticatedFetch({
        path: "businessline/practice/" + practiceId + "/carrier/" + carrierId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [practiceId, carrierId])


  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let businessLineOptionList = [<option value={0} key={0}>Select a plan...</option>];
    businessLineOptionList.push(data.map((bl, i) => {
      return (
        <option value={bl.id} key={bl.id}>{bl.name}</option>
      )
    }, this));

    return (
      <Form.Select onChange={(e) => {handleBusinessLineChanged(e)}} value={selectedBusinessLineId}>{businessLineOptionList}</Form.Select>
    )
  }
}

const FacilityRow = ({ onSave, activeFacilityIdsWithNullEffectiveDates, activeFacilityIdsWithNullExpires, practiceId, providerId, businessLineId, facilityId, facilityName, active, effectiveDate, expires}) => {
  const [editing, setEditing] = useState(false);
  const [localActive, setLocalActive] = useState(active);
  const [localExpires, setLocalExpires] = useState(expires);
  const [localEffectiveDate, setLocalEffectiveDate] = useState(effectiveDate);

  // Force a re-render in the case where another row says yes to an apply-all
  useEffect(() => {
    setLocalEffectiveDate(effectiveDate);
    setLocalExpires(expires);
  }, [effectiveDate, expires])
  
  const notyf = useContext(NotyfContext);

  const handleActiveChanged = (e) => {
    setLocalActive(e.target.checked);
    setLocalExpires(null);
    setLocalEffectiveDate(null);
  }

  const handleEffectiveDateChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalEffectiveDate(date);
  }

  const handleExpiresChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalExpires(date);
  }

  const cancelEdit = (e) => {
    e.preventDefault();
    setEditing(false);
    setLocalActive(active);
    setLocalExpires(expires);
    setLocalEffectiveDate(effectiveDate);
  }

  const handleEditClicked = (e) =>
  {
    e.preventDefault();
    setEditing(true);
  }
  

  const save = (e) =>
  {
    e.preventDefault();
    setEditing(false);

    let applyEffectiveDateToAllActive = false;
    if (localEffectiveDate !== null && localEffectiveDate !== effectiveDate)
    {
      if ((!activeFacilityIdsWithNullEffectiveDates.includes(facilityId) && activeFacilityIdsWithNullEffectiveDates.length > 0) || activeFacilityIdsWithNullEffectiveDates.length > 1)
      {
        if (window.confirm("Also apply this effective date to all other *active* locations for this line of business, if no effective date set?"))
        {
          applyEffectiveDateToAllActive = true;
        }
      }
    }

    let applyExpirationToAllActive = false;
    if (localExpires !== null && localExpires !== expires)
    {
      if ((!activeFacilityIdsWithNullExpires.includes(facilityId) || activeFacilityIdsWithNullExpires.length > 0) || activeFacilityIdsWithNullExpires.length > 1)
      {
        if (window.confirm("Also apply this expiration date to all other *active* locations for this line of business, if no expiration date set?"))
        {
          applyExpirationToAllActive = true;
        }
      }
    }

    authenticatedFetch({
      method: "PUT",
      path: "businessline/practice/" + practiceId + "/provider/" + providerId + "/businessline/" + businessLineId + "/facility/" + facilityId,
      requestData: {active: localActive, expiration: localExpires, effectiveDate: localEffectiveDate, applyEffectiveDateToAllActive: applyEffectiveDateToAllActive, applyExpirationToAllActive: applyExpirationToAllActive},
      successHandler: function() {
        onSave(practiceId); // easiest way to update expires (so undo works the next time) is just to have parent re-render us

        notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function() {
        setLocalActive(active);
        setLocalExpires(expires);
        setLocalEffectiveDate(effectiveDate);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <tr>
      <td>{facilityName}</td>
      <td>
      {editing ?
      <Form.Check value={localActive} checked={localActive} onChange={(e) => {handleActiveChanged(e)}}/>
      : <>{localActive ? <CheckCircle style={{width: "20px", height: "20px"}} className="text-success" /> : <XCircle style={{width: "20px", height: "20px"}} className="text-danger" />}</>
      }

      </td>
      <td>
        <EditableExpires warnWithColors={false} localExpires={localEffectiveDate} localActive={localActive} editingExpires={editing} handleExpiresChanged={handleEffectiveDateChanged} />
      </td>
      <td>
        <EditableExpires localExpires={localExpires} localActive={localActive} editingExpires={editing} handleExpiresChanged={handleExpiresChanged} />
      </td>
      <td style={{minWidth: "60px"}}><EditableExpiresControls editingExpires={editing} cancelEditExpires={cancelEdit} saveExpires={save} handleEditExpiresClicked={handleEditClicked} /></td>
    </tr>
  )
}


const FacilityList = ({ isLoaded, error, data, onSave, practiceId, providerId, businessLineId}) => {
  if (!isLoaded)
  {
    return (
      <Card style={{marginTop:"25px", minHeight: "200px"}}>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>Loading...</th>
            </tr>
          </thead>
        </Table>
      </Card>
    )
  }
  else if (error || !data)
  {
    return (
      <Card style={{marginTop:"25px", minHeight: "200px"}}>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>Error... Please reload the page...</th>
            </tr>
          </thead>
        </Table>
      </Card>
    )
  }
  else
  {
    let activeFacilityIdsWithNullEffectiveDates = data.filter((facility) => { return facility.active && facility.effectiveDate === null; }).map((facility) => { return facility.id; });
    let activeFacilityIdsWithNullExpires = data.filter((facility) => { return facility.active && facility.expires === null; }).map((facility) => { return facility.id; });

    let facilityOptionList = data.map((facility, i) => {
      return (
        <FacilityRow key={i} onSave={onSave} activeFacilityIdsWithNullEffectiveDates={activeFacilityIdsWithNullEffectiveDates} activeFacilityIdsWithNullExpires={activeFacilityIdsWithNullExpires} practiceId={practiceId} providerId={providerId} businessLineId={businessLineId} facilityId={facility.id} facilityName={facility.name} active={facility.active} effectiveDate={facility.effectiveDate} expires={facility.expires}/>
      )
    }, this);

    return (
      <Card style={{marginTop:"25px", minHeight: "200px"}}>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>Location</th>
              <th>Active</th>
              <th>Effective Date</th>
              <th>Expires</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {facilityOptionList}
          </tbody>
        </Table>
      </Card>
    )
  }
}

const ProviderBusinessLineList = ({ practiceId, providerId, carrierId, handleLineOfBusinessClicked}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (practiceId > 0 && providerId > 0 && carrierId > 0)
    {
      authenticatedFetch({
        path: "businessline/practice/" + practiceId + "/provider/" + providerId + "/carrier/" + carrierId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [practiceId, providerId, carrierId])


  if (!isLoaded)
    {
      return (
        <Card>
          <Card.Body style={{overflow: 'scroll', maxHeight: '500px', paddingTop: 0}}>
            <Table size="sm" striped hover>
              <thead>
                <tr>
                  <th>Loading...</th>
                </tr>
              </thead>
            </Table>
          </Card.Body>
        </Card>
      )
    }
    else if (error || !data)
    {
      return (
        <Card>
          <Card.Body style={{overflow: 'scroll', maxHeight: '500px', paddingTop: 0}}>
            <Table size="sm" striped hover>
              <thead>
                <tr>
                  <th>Error... Please reload the page...</th>
                </tr>
              </thead>
            </Table>
          </Card.Body>
        </Card>
      )
    }
    else
    {
      let businessLineList = data.map((lob, i) => {
        return (
          <tr key={lob.id}>
            <td><a onClick={(e) => handleLineOfBusinessClicked(e, lob.id)} href="#">{lob.name}</a></td>
            <td>{lob.earliestEffectiveDate === null ? <></> : <EditableExpires localExpires={lob.earliestEffectiveDate} localActive={true} editingExpires={false} warnWithColors={false} />}</td>
            <td>{lob.latestExpiration === null ? <></> : <EditableExpires localExpires={lob.latestExpiration} localActive={true} editingExpires={false} />}</td>
            </tr>
        )
      }, this);
  
      return (
        <Card>
          <Card.Body style={{overflow: 'scroll', maxHeight: '500px', paddingTop: 0}}>
            <Table size="sm" striped hover>
              <thead>
                <tr>
                  <th>Line of Business</th>
                  <th>Effective Date</th>
                  <th>Expires</th>
                </tr>
              </thead>
              <tbody>
                {businessLineList}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )
    }
}

const EditLineOfBusiness = ({practiceId, providerId, carrierId, selectedBusinessLineId, setSelectedBusinessLineId}) => {
  
  // Manage the facility state here so we can set back to loading when business line id changed
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [activateAllRunning, setActivateAllRunning] = useState(false);

  const notyf = useContext(NotyfContext);

  useEffect(() => {
    if (practiceId > 0 && providerId > 0 && selectedBusinessLineId > 0)
    {
      reload();
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [practiceId, providerId, selectedBusinessLineId])

  const reload = () => {
    authenticatedFetch({
      path: "businessline/practice/" + practiceId + "/provider/" + providerId + "/businessline/" + selectedBusinessLineId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  const handleBusinessLineChanged = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setData([]);
    setSelectedBusinessLineId(e.target.value);
  }

  const handleActivateAll = () => {
    if (!window.confirm("Activate any inactive business lines for this provider for *all* applicable locations?"))
    {
      return;
    }

    setActivateAllRunning(true);

    authenticatedFetch({
      method: "POST",
      path: "businessline/practice/" + practiceId + "/provider/" + providerId + "/carrier/" + carrierId,
      successHandler: function(result) {
        setActivateAllRunning(false);
        notyf.open({type: "success", message: "Activated " + result.count + " business line + location combination(s)!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setActivateAllRunning(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }


  return (
    <Table className="table-borderless table-sm"> 
      <tbody>
        <tr><td><BusinessLineSelect practiceId={practiceId} carrierId={carrierId} selectedBusinessLineId={selectedBusinessLineId} handleBusinessLineChanged={handleBusinessLineChanged} /></td></tr>
        {selectedBusinessLineId > 0 &&
        <tr><td><FacilityList onSave={reload} isLoaded={isLoaded} error={error} data={data} practiceId={practiceId} providerId={providerId} businessLineId={selectedBusinessLineId}/></td></tr>
        }
        {selectedBusinessLineId == 0 &&
        <>
        <tr>
          <td>
            <br />
            {activateAllRunning ? 
            <Spinner className="me-auto" animation="border" size="sm" variant="primary" style={{marginLeft: '25px'}}/>
              : <Button className="me-auto" onClick={handleActivateAll} size="sm">Activate All</Button>
            }
          </td>
        </tr>
        </>
        }
      </tbody>
    </Table>
  )
}

const LinesOfBusinessModal = ({ show, onClose, practiceId, providerId, providerName, carrierId, practiceName, carrierName}) => {

  // Manage this state here so we can clear on close
  const[selectedBusinessLineId, setSelectedBusinessLineId] = useState(0);
  
  const [selectedMode, setSelectedMode] = useState("VIEW");


  const handleModeChanged = (mode) => {
    setSelectedMode(mode);
    setSelectedBusinessLineId(0);
  }


  const handleClose = () => {
    setSelectedBusinessLineId(0);
    onClose();
  }

  const handleLineOfBusinessClicked = (e, businessLineId) => {
    e.preventDefault();
    setSelectedMode("EDIT");
    setSelectedBusinessLineId(businessLineId);
  }

  return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Lines of Business for {providerName}</span><br />{practiceName} | {carrierName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { selectedMode === "VIEW" ?
          <ProviderBusinessLineList practiceId={practiceId} providerId={providerId} carrierId={carrierId} handleLineOfBusinessClicked={handleLineOfBusinessClicked}/>
          :
          <EditLineOfBusiness practiceId={practiceId} providerId={providerId} carrierId={carrierId} selectedBusinessLineId={selectedBusinessLineId} setSelectedBusinessLineId={setSelectedBusinessLineId}/>
          }
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          <ViewEditSelect selectedMode={selectedMode} handleModeChanged={handleModeChanged}/>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
}


export default LinesOfBusinessModal;
