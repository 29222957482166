import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Container, Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import Spinner from 'react-bootstrap/Spinner';

import authenticatedFetch from "../utils/fetch"

import NotyfContext from "../contexts/NotyfContext";

import { UserSettingsContext } from "../contexts/UserSettingsContext";


import SlimPracticePicker from "../utils/SlimPracticePicker"

import { Trash2 } from "react-feather";


const PracticeTemplateEdit = ({practiceId, templateId, canEdit, maxOrder, handleCancel, handleReload}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [localName, setLocalName] = useState("");
  const [localContent, setLocalContent] = useState("");
  const [localOrder, setLocalOrder] = useState(0);
  const [localMapping, setLocalMapping] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const notyf = useContext(NotyfContext);

  if (data && data.mapping == null)
  {
    data.mapping = "";
  }

  let saveEnabled = localName.length > 0 && localContent.length > 0 && localOrder !== "" && localOrder >= 0 && (localName !== data.name || localContent !== data.content || localOrder !== data.order || localMapping !== data.mapping);

  useEffect(() => {
    if (templateId > 0)
    {
      authenticatedFetch({
        path: "sms/" + practiceId + "/template/" + templateId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
          setLocalName(result.name);
          setLocalContent(result.content);
          setLocalOrder(result.order);
          setLocalMapping(result.mapping ? result.mapping : "");
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
    else
    {
      setLocalName("");
      setLocalContent("");
      setLocalMapping("");
      setLocalOrder(maxOrder + 1);
      setIsLoaded(true);
    }
  }, [practiceId, templateId, maxOrder])

  const handleDelete = (e) => {
    if (!window.confirm("Are you sure you want to delete this template?"))
    {
      return;
    }

    setIsSaving(true);

    authenticatedFetch({
      method: "DELETE",
      path: "sms/" + practiceId + "/template/" + templateId,
      successHandler: function(result) {
        handleReload();
        notyf.open({type: "success", message: "Deleted!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleSave = (e) => {
    e.preventDefault();

    // Match everything between brackets {} that is not one of date, time, or provider, case insensitive
    var regex = /{(?!(date|time|provider))[^{}]*}/gi;
    if (localContent.match(regex))
    {
      if (!window.confirm("The following wildcard placeholders do not have corresponding widgets in the Messaging tool:\n\n" + localContent.match(regex).join(", ") + "\n\nAre you sure you want to save this template?"))
      {
        return;
      }
    }

    setIsSaving(true);

    authenticatedFetch({
      method: templateId > 0 ? "PUT" : "POST",
      requestData: {order: localOrder, name: localName, content: localContent, mapping: localMapping},
      path: "sms/" + practiceId + "/template/" + (templateId > 0 ? templateId : ""),
      successHandler: function(result) {
        handleReload();
        notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  if (!isLoaded)
  {
    return <tr><td colSpan={2}>Loading...</td></tr>
  }
  else if (error)
  {
    <tr><td colSpan={2}>Error... Please reload the page...</td></tr>
  }
  return (
    <>
      <tr>
        <td style={{width: "200px"}}>Order (for display):</td>
        <td><Form.Control type="number" value={localOrder} onChange={(e) => {setLocalOrder(e.target.value)}} /></td>
      </tr>
      <tr>
        <td>Name:</td>
        <td><Form.Control type="text" value={localName} onChange={(e) => {setLocalName(e.target.value)}} /></td>
      </tr>
      <tr>
        <td>Content:</td>
        <td><Form.Control as="textarea" style={{minHeight: "250px"}} maxLength={999} value={localContent} onChange={(e) => {setLocalContent(e.target.value)}} /></td>
      </tr>
      <tr>
        <td>Mapping (optional, comma-separated):</td>
        <td><Form.Control type="text" value={localMapping} onChange={(e) => {setLocalMapping(e.target.value)}} /></td>
      </tr>
      <tr>
        <td colSpan={2}>&nbsp;</td>
      </tr>
      {canEdit &&
        <tr>
          <td colSpan={2}>
            {isSaving ? <Spinner animation="border" size="sm" style={{marginLeft: "10px", marginBottom: "10px"}} /> :
            <>
              <Button disabled={!saveEnabled} onClick={(e) => {handleSave(e)}}>Save</Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="secondary" onClick={(e) => {handleCancel(e)}}>Cancel</Button>
              {templateId > 0 && <Button variant="danger" className="float-end" onClick={(e) => {handleDelete(e)}}><Trash2 style={{'height': '14px', 'width': '14px'}}/>&nbsp;Delete Template</Button>}
            </>
            }
          </td>
        </tr>
      }
    </>
  )

};

const PracticeTemplates = ({practiceId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);
  const [addingNewTemplate, setAddingNewTemplate] = useState(false);
  
  const [maxOrder, setMaxOrder] = useState(0);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  let canEdit = isH3Admin || authorities.includes("MESSAGING_EDIT");
  
  useEffect(() => {
    reload();
  }, [practiceId])

  const reload = () => {
    authenticatedFetch({
      path: "sms/" + practiceId + "/templates",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
        setMaxOrder(0);
        for (var i=0; i<result.length; i++)
        {
          if (result[i].order > maxOrder)
          {
            setMaxOrder(result[i].order);
          }
        }
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  const onTemplateChanged = (e) => {
    e.preventDefault();
    setSelectedTemplateId(parseInt(e.target.value));
  }
  
  const handleAddNewTemplate= (e) => {
    e.preventDefault();
    setSelectedTemplateId(0);
    setAddingNewTemplate(true);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setSelectedTemplateId(0);
    setAddingNewTemplate(false);
  }

  const handleReload = () => {
    setSelectedTemplateId(0);
    setAddingNewTemplate(false);
    reload();
  }

  var templateList;
  if (!isLoaded)
  {
    templateList = [<option value={0} key={0}>Loading...</option>];
  }
  else if (error || !data)
  {
    templateList = [<option value={0} key={0}>Error... Please reload the page...</option>];
  }
  else
  {
    templateList = [<option value={0} key={0}>Select an existing template...</option>];
    templateList.push(data.map((template, i) => {
      return (
        <option value={template.id} key={template.id}>{template.order}: {template.name}</option>
      )
    }, this));
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Template Management

          {!addingNewTemplate && !selectedTemplateId > 0 && canEdit &&
          <Button className="float-end btn btn-primary" onClick={(e) => {handleAddNewTemplate(e)}}>+ Add New Template</Button>}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="table-borderless table-sm">
          <tbody>
            { !addingNewTemplate &&
            <tr>
              <td style={{width: "200px"}}>Template:</td>
              <td>
                <Form.Select value={selectedTemplateId} onChange={(e) => {onTemplateChanged(e)}} >
                  {templateList}
                </Form.Select>
              </td>
            </tr>
            }
            {(selectedTemplateId > 0 || addingNewTemplate) && <PracticeTemplateEdit practiceId={practiceId} templateId={selectedTemplateId} canEdit={canEdit} maxOrder={maxOrder} handleReload={handleReload} handleCancel={handleCancel}/>
            }
          </tbody>
        </Table>
        </Card.Body>
      </Card>
  )
};


const MessagingTemplates = () => {
  const [practiceId, setPracticeId] = useState(0);
  const [practiceName, setPracticeName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "sms/practices",
      successHandler: function(result) {
        if (result && result.length === 1)
        {
          // Skip the practice picker if there is only one practice
          setPracticeId(result[0].id);
          setPracticeName(result[0].name);
        }

        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

  }, []);

  const onPracticeChanged = (e) => {
    e.preventDefault();
    setPracticeId(e.target.value);
    setPracticeName(e.target.options[e.target.selectedIndex].text)
  }

  const handleSelectDifferentPractice = (e) => {
    e.preventDefault();
    setPracticeId(0);
    setPracticeName(null)
  }

  const title = "Messaging Templates" + (practiceName != null ? " | " + practiceName : "")

  return (
    <React.Fragment>
        <Helmet title={title} />
        <h1 className="h3 mb-3">{title}</h1>
        {practiceId > 0 && data.length > 1 ? <><Button variant="secondary" size="sm" onClick={(e) => {handleSelectDifferentPractice(e)}}>&lt; Select a different practice</Button><br /><br /></> : <></>}
        <Container fluid className="p-0">
          <Row>
            <Col lg="8" md="12">
              { practiceId > 0 ? <PracticeTemplates practiceId={practiceId} /> : <SlimPracticePicker isLoaded={isLoaded} error={error} data={data} onPracticeChanged={onPracticeChanged} /> }    
            </Col>
          </Row>
      </Container>
    </React.Fragment>
  )
};

export default MessagingTemplates;
